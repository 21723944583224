/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import JumpComp from '../components/Jump';
import { generateUUID } from '../helpers/uids';
import useTransform from '../hooks/useTransform';
import { getChannelData } from '../actions/cpc';
import { getDelinquency } from '../actions/bill';
import { getIdentityAndAccountParams } from '../helpers/account';
import { flagEnabled } from '../helpers/featureFlags';

const Jump = ({
  accountChannelData, // from /apis/channel-data response
  channelData, // {Object} Additional channel data
  getChannelData,
  getDelinquency,
  channelDataReady,
  isNowPage,
  user,
  macaroon,
  delinquency,
  // Rest of the props we want to pass along to JumpComp
  ...props
}) => {
  useEffect(() => {
    if (!channelDataReady) {
      getChannelData();
      getDelinquency(); // for enhanced transaction data
    }
  }, [channelDataReady, getChannelData, getDelinquency]);
  // Feature flags
  const txnDataTokenizationEnabled = flagEnabled('txnDataTokenizationEnabled');
  const txnDataPaymentEnabled = flagEnabled('txnDataPaymentEnabled');
  const plaidEnabled = sessionStorage.getItem('mboxFlag_exp1468') === '1';

  const nowChannel = 'NOW_TOKEN';
  const [trackingId] = useState(generateUUID());

  const isFromApp = sessionStorage.getItem('isFromApp') === 'true';

  const txnDataEnabled = txnDataTokenizationEnabled || txnDataPaymentEnabled;
  const fingerprintChannelName = isNowPage
    ? nowChannel
    : accountChannelData?.channelDetails?.channelName;
  const txnDataChannelData = {
    channelDetails: {
      ...(props.cpcPageType === 'DeviceFingerPrintId' && { fingerprintChannelName }),
    },
    customerDetails: {
      ...getIdentityAndAccountParams(macaroon, delinquency),
    },
  };

  // Used for bank pages. Can be passed for all page types though and CPC just ignores it.
  // Passing 'native' or 'legacy_web' to cpc will load up the manual bank entry form.
  const webAppType = plaidEnabled ? 'web' : 'legacy_web';
  const applicationType = isFromApp ? 'native' : webAppType;
  const clientName = 'Xfinity';

  const defaultChannelData = {
    ...accountChannelData,
    ...channelData,
    channelDetails: {
      ...accountChannelData?.channelDetails,
      ...channelData?.channelDetails,
      sourceServerId: window.location.hostname,
      trackingId,
      sessionId: window.MAW?.sessionID,
      applicationType,
      clientName,
      ...(txnDataEnabled && txnDataChannelData.channelDetails),
    },
    customerDetails: {
      ...accountChannelData?.customerDetails,
      ...channelData?.customerDetails,
      ...(txnDataEnabled && txnDataChannelData.customerDetails),
    },
  };
  const nowChannelData = {
    ...defaultChannelData,
    channelDetails: {
      ...defaultChannelData.channelDetails,
      channelName: nowChannel,
      sourceSystemId: `${nowChannel}_CPC`,
      enableFraudManager: true,
    },
    customerDetails: {
      ...defaultChannelData.customerDetails,
      billingArrangementId: null,
      emailAddress: user?.preferredEmail,
      phone: user?.homePhone || user?.mobilePhone,
      ...(txnDataEnabled && { accountCustomerClass: 'NOW' }),
    },
    channelCustomData: {
      channelCustomDataField8: `${nowChannel}_SERVICE`,
      channelCustomDataField10: accountChannelData?.customerDetails?.billingArrangementId,
    },
  };
  const finalChannelData = useTransform(() => ({
    ...(isNowPage ? nowChannelData : defaultChannelData),
  }), [accountChannelData]);

  if (!channelDataReady) return null;
  return (
    <JumpComp
      {...props}
      channelData={finalChannelData}
      channelDataReady={channelDataReady}
      deviceFingerprintingEnabled={txnDataEnabled}
    />
  );
};

const mapStateToProps = ({
  bill: { delinquency = {} },
  cpc: {
    loading: channelDataLoading,
    channelData,
  } = {},
  user: { user } = {},
  auth: { macaroon } = {},
}) => ({
  channelDataReady: !channelDataLoading && !!channelData,
  accountChannelData: channelData,
  user,
  macaroon,
  delinquency,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getChannelData,
  getDelinquency,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Jump);
